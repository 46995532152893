import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from '../../style'

// Sections
import Header from '../../limite-credito/sections/header/_index'
import LimiteNaHora from '../../limite-credito/sections/limiteNaHora/_index'
import CartaoInterMastercard from '../../limite-credito/sections/cartao-inter-mastercard/_index'
import LibereMaisLimite from '../../limite-credito/sections/libereMaisLimite/_index'
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'
import AtivacaoModal1 from 'src/components/UI/Forms/InterAtivacaoClientesForm1'
import { Modal } from 'src/components/Modal'
import HelpCenter from '../../helpCenter/_index'

import pageContext from './pageContext.json'

const LimiteCreditoPJ = () => {
  const page = 'PJ'
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openModal1, setOpenModal1 ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )
  const openAccountPJFormModal1 = domReady && (
    <Modal isModalOpen={openModal1} setIsModalOpen={setOpenModal1} locationToRender={document.body}>
      <AtivacaoModal1 closeModal={() => setOpenModal1(false)} page={page} />
    </Modal>
  )
    return (
      <Wrapper>
        <Layout pageContext={pageContext}>
          {openAccountPJFormModal}
          {openAccountPJFormModal1}
          <Header page={page} />
          <LimiteNaHora setOpenModal1={setOpenModal1} page={page} />
          <CartaoInterMastercard setOpenModal1={setOpenModal1} page={page} />
          <LibereMaisLimite setOpenModal={setOpenModal} setOpenModal1={setOpenModal1} page={page} />
          <HelpCenter bgColor='color-green' />
        </Layout>
      </Wrapper>
    )
}

export default LimiteCreditoPJ
